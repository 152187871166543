import React, { Component } from "react";
import NavBar from "../components/Homepage/NavBar/NavBar";
import Banner from "../components/Homepage/Banner/Banner";
import About from "../components/Homepage/About";
import Timeline from "../components/Homepage/Timeline/Timeline";
import Skills from "../components/Homepage/Skills/Skills";
import Experience from "../components/Homepage/Experience/Experience";
import Projects from "../components/Homepage/Projects/Projects";
import Contact from "../components/Homepage/Contact";
import Footer from "../components/Homepage/Footer/Footer";
import Layout from "../components/Layout/Layout";
import FindOutMore from "../components/Homepage/FindOutMore";

const components = [
  <About />,
  <Timeline />,
  <Skills />,
  // TODO
  // <Experience />,
  // TODO
  // <Projects />,
  <FindOutMore />,
  <Contact />,
];

const Homepage = () => (
  <>
    {/* <NavBar /> */}
    <Banner />
    {components.map((component, i) => (
      <Layout>{component}</Layout>
    ))}
    <Footer />
  </>
);

export default Homepage;

import React from "react";
import { Box } from "@material-ui/core";

import "./styles.css";
import Link from "../../Link/Link";
import Typography from "../../Typography/Typography";
import { colorMap } from "../../Typography/color";

const Timeline = () => (
  <div style={{ backgroundColor: colorMap["mid-grey"] }}>
    <Box pt={3}>
      <div className="anchor" id="timeline">
        <section className="timeline-section-1" id="timeline-section">
          <div
            className="section-title text-center wow fadeInDown"
            data-wow-duration="2s"
            data-wow-delay="50ms"
          >
            <Typography variant="h2">Timeline</Typography>
            <Typography variant="p">What have I been up to?</Typography>
            <br />
            <Link
              className="page-scroll btn btn-primary"
              href="https://drive.google.com/file/d/1PLLlaT2KWt7c6VM_dWrD9YVe-y5psowm/view"
              target="_blank"
              rel="nofollow"
            >
              View CV
            </Link>
            <div className="timeline">
              <div className="timeline-marker"></div>
              <div className="entry">
                <div className="title">
                  <h3>June 2021 - Present</h3>
                </div>
                <div className="body">
                  <Typography variant="p">
                    <a href="https://grafana.com/about/mission/">Grafana</a>
                  </Typography>
                  <ul>
                    <li>Engineering Manager</li>
                  </ul>
                </div>
              </div>
              <div className="entry">
                <div className="title">
                  <h3>November 2020 - June 2021</h3>
                </div>
                <div className="body">
                  <Typography variant="p">
                    <a href="https://grafana.com/about/mission/">Grafana</a>
                  </Typography>
                  <ul>
                    <li>Software Engineer</li>
                  </ul>
                </div>
              </div>
              <div className="entry">
                <div className="title">
                  <h3>June 2019 - October 2020</h3>
                </div>
                <div className="body">
                  <Typography variant="p">
                    <a href="https://gocardless.com/about">GoCardless</a>
                  </Typography>
                  <ul>
                    <li>Software Development Engineer</li>
                  </ul>
                </div>
              </div>
              <div className="entry">
                <div className="title">
                  <h3>February 2018 - June 2019</h3>
                </div>
                <div className="body">
                  <Typography variant="p">
                    <a href="https://trussle.com/about-us">Trussle</a>
                  </Typography>
                  <ul>
                    <li>Software Engineer</li>
                  </ul>
                </div>
              </div>
              <div className="entry">
                <div className="title">
                  <h3>August 2016 - January 2018</h3>
                </div>
                <div className="body">
                  <Typography variant="p">
                    <a href="https://www.thoughtworks.com/about-us">
                      ThoughtWorks
                    </a>
                  </Typography>
                  <ul>
                    <li>Software Developer</li>
                  </ul>
                </div>
              </div>
              <div className="entry">
                <div className="title">
                  <h3>July 2016</h3>
                </div>
                <div className="body">
                  <Typography variant="p">
                    Graduated From{" "}
                    <a href="https://www.cs.york.ac.uk/undergraduate/ug-courses/meng-cs-ai-industry/">
                      University of York
                    </a>
                  </Typography>
                  <ul>
                    <li>
                      MEng Computer Science with Artificial Intelligence (with a
                      year in industry)
                    </li>
                    <ul>
                      <li>First Class Honours</li>
                    </ul>
                  </ul>
                </div>
              </div>
              <div className="entry">
                <div className="title">
                  <h3>June 2015 - September 2015</h3>
                </div>
                <div className="body">
                  <Typography variant="p">
                    <a href="http://www.snclavalin.com/en/about-us/">
                      SNC-Lavalin
                    </a>
                  </Typography>
                  <ul>
                    <li>Associate Software Developer</li>
                  </ul>
                </div>
              </div>
              <div className="entry">
                <div className="title">
                  <h3>August 2014</h3>
                </div>
                <div className="body">
                  <Typography variant="p">
                    <a href="http://www.snclavalin.com/en/about-us/">
                      SNC-Lavalin
                    </a>
                  </Typography>
                  <ul>
                    <li>Summer Intern</li>
                  </ul>
                </div>
              </div>
              <div className="entry">
                <div className="title">
                  <h3>July 2013 - July 2014</h3>
                </div>
                <div className="body">
                  <Typography variant="p">
                    <a href="https://www.lighthousesystems.com/about">
                      Lighthouse Systems
                    </a>
                  </Typography>
                  <ul>
                    <li>Support Project Engineer</li>
                  </ul>
                </div>
              </div>
              <div className="entry">
                <div className="title">
                  <h3>October 2011</h3>
                </div>
                <div className="body">
                  <Typography variant="p">
                    Began Studies at{" "}
                    <a href="https://www.cs.york.ac.uk/undergraduate/ug-courses/meng-cs-ai-industry/">
                      {" "}
                      University of York
                    </a>
                  </Typography>
                  <ul>
                    <li>
                      MEng Computer Science with Artificial Intelligence (with a
                      year in industry)
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </section>
      </div>
    </Box>
  </div>
);

export default Timeline;

import React from "react";
import { Box } from "@material-ui/core";
import Typography from "../../Typography/Typography";

const style: React.CSSProperties = {
  borderTop: "1px solid #363636",
  padding: "1.5rem 0 1.5rem 1.5rem",
  background: "#1d1d1d",
};

const Footer = () => {
  return (
    <Box pt={6}>
      <div style={style}>
        <Box textAlign="left">
          <Typography color="light-grey">
            Vicky Lee © {new Date().getFullYear()}
          </Typography>
        </Box>
      </div>
    </Box>
  );
};

export default Footer;

import React from "react";
import { Grid, Box } from "@material-ui/core";
import { chunk } from "lodash";
import AssessmentOutlinedIcon from "@material-ui/icons/AssessmentOutlined";
import CodeOutlinedIcon from "@material-ui/icons/CodeOutlined";
import CloudOutlinedIcon from "@material-ui/icons/CloudOutlined";
import ErrorOutlineOutlinedIcon from "@material-ui/icons/ErrorOutlineOutlined";
import HttpOutlinedIcon from "@material-ui/icons/HttpOutlined";
import CreateOutlinedIcon from "@material-ui/icons/CreateOutlined";
import StorageOutlinedIcon from "@material-ui/icons/StorageOutlined";
import DeviceHubOutlinedIcon from "@material-ui/icons/DeviceHubOutlined";
import Typography from "../../Typography/Typography";
import { useTheme } from "@material-ui/core/styles";
import useMediaQuery from "@material-ui/core/useMediaQuery";

const ICON_STYLE = { color: "red", fontSize: 50 };

const SKILLS_PER_ROW = 4;

const skills = [
  {
    icon: <CodeOutlinedIcon style={ICON_STYLE} />,
    category: "Programming languages",
    description: "Back end for many full stack web applications.",
    techStack: "Node.js/JavaScript (ES6/ES7), Python, Go, Ruby, C#, Java",
  },
  {
    icon: <HttpOutlinedIcon style={ICON_STYLE} />,
    category: "Libraries/Frameworks",
    description:
      "Making things look pretty. Cross-browser compatibility is a pain.",
    techStack:
      "React, Typescript, GraphQL, AngularJS, Pug/Jade, next.js, Webpack, HTML/CSS, Sass",
  },
  {
    icon: <ErrorOutlineOutlinedIcon style={ICON_STYLE} />,
    category: "Testing",
    description:
      "Make sure things do not break. Includes unit testing, UI testing, performance testing.",
    techStack:
      "Cypress, TestCafe, Protractor, Nightwatch, Selenium, BrowserStack",
  },
  {
    icon: <CreateOutlinedIcon style={ICON_STYLE} />,
    category: "CMS",
    description: "Updating content should not require engineering effort.",
    techStack: "Contentful, Prismic",
  },
  {
    icon: <DeviceHubOutlinedIcon style={ICON_STYLE} />,
    category: "Infrastructure",
    description:
      "You kinda need to know what happens after you commit your code.",
    techStack: "CircleCI, Drone, Jenkins, Docker, Kubernetes, Terraform, GoCD",
  },
  {
    icon: <StorageOutlinedIcon style={ICON_STYLE} />,
    category: "Data",
    description: "From retrieving and storing data to building data pipelines.",
    techStack: "BigQuery, Airflow, Looker, SQL, PostgreSQL, DynamoDB, Oracle",
  },
  {
    icon: <CloudOutlinedIcon style={ICON_STYLE} />,
    category: "Cloud Computing",
    description:
      "DevOps infrastructure as code, serverless computing or CDNs and alarms/monitoring.",
    techStack: "AWS, Google Cloud Platform",
  },
  {
    icon: <AssessmentOutlinedIcon style={ICON_STYLE} />,
    category: "Observability",
    description: "What metrics and logging should you measure and observe?",
    techStack: "Prometheus, Grafana, ElasticSearch, Kibana",
  },
];

const skillsGrid = () => {
  const components = skills.map((skill) => (
    <Grid item xs={12} sm={6} lg={3}>
      <Box>
        {skill.icon}
        <Typography variant="h3">{skill.category}</Typography>
        <Typography variant="p">{skill.description}</Typography>
        <Typography variant="p">{skill.techStack}</Typography>
      </Box>
    </Grid>
  ));

  const rows = chunk(components, SKILLS_PER_ROW);

  return rows.map((row, index) => (
    <>
      {index > 0 ? <Box p={2} /> : null}
      <Grid container>
        <Grid item xs={12}>
          <Grid container justify="center" spacing={10}>
            {row}
          </Grid>
        </Grid>
      </Grid>
    </>
  ));
};

const Skills = () => {
  const theme = useTheme();
  const matchesXs = useMediaQuery(theme.breakpoints.up("xs"));
  const matchesSm = useMediaQuery(theme.breakpoints.up("sm"));
  const matchesLg = useMediaQuery(theme.breakpoints.up("lg"));

  return (
    <Box>
      <Typography variant="h2">Skills</Typography>
      <Typography variant="p">
        A brief overview on some of the technical stuff I've picked up over the
        years.
      </Typography>
      <Box pt={6} px={matchesLg ? 20 : matchesSm ? 10 : 4}>
        {skillsGrid()}
      </Box>
    </Box>
  );
};

export default Skills;

import React from "react";
import { Box, Grid } from "@material-ui/core";
import EmailIcon from "@material-ui/icons/Email";
import LinkedInIcon from "@material-ui/icons/LinkedIn";
import GitHubIcon from "@material-ui/icons/GitHub";
import Link from "../Link/Link";
import Typography from "../Typography/Typography";
import { useTheme } from "@material-ui/core/styles";
import useMediaQuery from "@material-ui/core/useMediaQuery";

const ICON_STYLE = { fontSize: 70 };

const Contact = () => {
  const theme = useTheme();
  const matchesXs = useMediaQuery(theme.breakpoints.up("xs"));
  const matchesSm = useMediaQuery(theme.breakpoints.up("sm"));
  const matchesLg = useMediaQuery(theme.breakpoints.up("lg"));

  return <Box px={matchesLg ? 20 : matchesSm ? 10 : 4}>
    <Box pb={5}>
      <Typography variant="h2">Talk to me</Typography>
      <Typography variant="p">Wanna chat? Feel free to reach out.</Typography>
    </Box>
    <Grid container justify="center" spacing={5}>
      <Grid item xs={12} sm={4}>
        <Box>
          <Link href="https://www.github.com/vickyyyyyyy/">
            <GitHubIcon style={ICON_STYLE} />
          </Link>
        </Box>
      </Grid>

      <Grid item xs={12} sm={4}>
        <Box>
          <Link href="https://www.linkedin.com/in/vickywklee/">
            <LinkedInIcon style={ICON_STYLE} />
          </Link>
        </Box>
      </Grid>

      <Grid item xs={12} sm={4}>
        <Box>
          <Link href="mailto:vicky.winkay.lee@gmail.com">
            <EmailIcon style={ICON_STYLE} />
          </Link>
        </Box>
      </Grid>
    </Grid>
  </Box>
};

export default Contact;

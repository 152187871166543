import React from "react";
import { Avatar, Box, Grid, List, ListItem, ListItemAvatar, ListItemText } from "@material-ui/core";
import Typography from "../Typography/Typography";
import { useTheme } from "@material-ui/core/styles";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import moment from "moment";

type Company = "grafana" | "gc" | "trussle"

const ASSERT_URL = "https://s3.eu-west-2.amazonaws.com/vickylee.codes/uploaded-assets/"

const getAssertUrl = (company: string): string => `${ASSERT_URL}${company}.png`

interface BlogPost {
  title: string
  url: string
  date: string
  company: Company
}

const blogPosts: BlogPost[] = [
  {
    title: "Grafana Labs Hackathon 2021: The story behind Grafana Stories™",
    url: "https://grafana.com/blog/2021/10/15/grafana-labs-hackathon-2021-the-story-behind-grafana-stories/",
    date: "2021-10-15",
    company: "grafana"
  },
  {
    title: "Panel: Careers in open source",
    url: "https://grafana.com/go/grafanaconline/2021/careers-open-source",
    date: "2021-06-15",
    company: "grafana"
  },
  {
    title: "Meet the Grafana Labs team: Software engineer Vicky Lee, who builds Enterprise plugins for Grafana",
    url: "https://grafana.com/blog/2021/03/19/meet-the-grafana-labs-team-software-engineer-vicky-lee-who-builds-enterprise-plugins-for-grafana/",
    date: "2021-03-19",
    company: "grafana"
  },
  {
    title: "Pride month: Recognising the power of being heard",
    url: "https://gocardless.com/blog/pride-month-vicky-lee/",
    date: "2020-06-01",
    company: "gc"
  },
  {
    title: "How to Hire and Retain the Best",
    url: "https://trussle.github.io/2019/03/20/websummit-hiring.html",
    date: "2019-03-20",
    company: "trussle"
  },
  {
    title: "SEO Market Trends",
    url: "https://trussle.github.io/2019/03/13/websummit-seo.html",
    date: "2019-03-13",
    company: "trussle"
  },
  {
    title: "Marketing for Startups",
    url: "https://trussle.github.io/2019/03/06/websummit-marketing.html",
    date: "2019-03-06",
    company: "trussle"
  },
  {
    title: "How to Cope with Competition",
    url: "https://trussle.github.io/2019/02/27/websummit-competition.html",
    date: "2019-02-27",
    company: "trussle"
  },
  {
    title: "What Happens After Product Meets Market?",
    url: "https://trussle.github.io/2019/02/20/websummit-product-fit.html",
    date: "2019-02-20",
    company: "trussle"
  },
  {
    title: "Improving our End-to-End Testing Culture with TestCafe",
    url: "https://trussle.github.io/2019/01/16/e2e-with-testcafe.html",
    date: "2019-01-16",
    company: "trussle"
  },
]

const FindOutMore = () => {
  const theme = useTheme();
  const matchesXs = useMediaQuery(theme.breakpoints.up("xs"));
  const matchesSm = useMediaQuery(theme.breakpoints.up("sm"));
  const matchesLg = useMediaQuery(theme.breakpoints.up("lg"));

  return <Box px={matchesLg ? 20 : matchesSm ? 10 : 4}>
    <Box>
      <Typography variant="h2">Find out more</Typography>
      <Typography variant="p">Read my blog posts.</Typography>
    </Box>
    <Grid container justify="center">
      <List>
      {
        blogPosts.map((post) =>
          <>
          <ListItem button component="a" href={post.url} target="_blank">
            <ListItemAvatar>
              <Avatar>
                <img src={getAssertUrl(post.company)} width="100%"/>
              </Avatar>
            </ListItemAvatar>
            <ListItemText primary={post.title} secondary={moment(new Date(post.date)).format('DD MMMM YYYY')} />
          </ListItem>
          </>
          )
      }
      </List>
    </Grid>
  </Box>
};

export default FindOutMore;

import React from "react";

import "./styles.css";

const style: React.CSSProperties = {
  textTransform: "uppercase",
};

const Link = (props: React.AnchorHTMLAttributes<HTMLAnchorElement>) => {
  const { children, ...rest } = props;
  return (
    <a style={style} {...rest}>
      {children}
    </a>
  );
};

export default Link;

import React from "react";
import ReactDOM from "react-dom";
import "./index.css";
import Homepage from "./pages/Homepage";
import LyricsGenerator from "./pages/LyricsGenerator";
import { BrowserRouter, Switch, Route } from "react-router-dom";

const Routes = () => (
  <BrowserRouter>
    <Switch>
      {/* <Route path="/lyrics-generator">
        <LyricsGenerator />
      </Route> */}
      <Route path="/">
        <Homepage />
      </Route>
    </Switch>
  </BrowserRouter>
);

ReactDOM.render(<Routes />, document.getElementById("root"));

import React from "react";
import { Box } from "@material-ui/core";
import Button from "../../Button/Button";
import Typography from "../../Typography/Typography";
import background from "./me.jpeg";

const styles = {
  background: {
    background: `url(${background}) no-repeat`,
    backgroundPosition: "center center",
    backgroundSize: "cover",
  },
  overlay: {
    background: "rgba(0, 0, 0, 0.5)",
    padding: "120px 0",
  },
};

const Banner = () => (
  <div style={styles.background}>
    <Box style={styles.overlay}>
      <Typography variant="h1" style={{ fontSize: "50px" }}>
        <span style={{ color: 'white', textTransform: 'uppercase', letterSpacing: '5px' }}>Hi there, </span>
        <span style={{ color: '#ff432e', textTransform: 'uppercase', letterSpacing: '5px' }}>I'm Vicky.</span>
      </Typography>
      <Typography className="oswald" variant="p" style={{ color: "white", fontSize: "30px", fontWeight: 'lighter', letterSpacing: '3px' }}>Engineering Manager</Typography>
      <a href="#about"><Button>Who am I</Button></a>
    </Box>
  </div>
);

export default Banner;

import React from "react";
import { Color, colorMap } from "./color";

type TypographyVariant = "h1" | "h2" | "h3" | "p" | "span";

const headerStyle: React.CSSProperties = {
  textTransform: "uppercase",
};

const textStyle: React.CSSProperties = {
  color: colorMap.grey,
};


const variantMap: Record<
  TypographyVariant,
  (props: Props, style?: React.CSSProperties) => JSX.Element
> = {
  h1: (
    props: React.HTMLAttributes<HTMLHeadingElement>,
    style?: React.CSSProperties
  ) => <h1 className="oswald" style={{ ...headerStyle, ...style }} {...props} />,
  h2: (
    props: React.HTMLAttributes<HTMLHeadingElement>,
    style?: React.CSSProperties
  ) => <h2 className="oswald" style={{ fontSize: '40px', ...headerStyle, ...style }} {...props} />,
  h3: (
    props: React.HTMLAttributes<HTMLHeadingElement>,
    style?: React.CSSProperties
  ) => <h3 style={style} {...props} />,
  p: (
    props: React.HTMLAttributes<HTMLParagraphElement>,
    style?: React.CSSProperties
  ) => <p style={{ ...textStyle, ...style }} {...props} />,
  span: (
    props: React.HTMLAttributes<HTMLSpanElement>,
    style?: React.CSSProperties
  ) => <span style={{ ...textStyle, ...style }} {...props} />,
};

interface CustomProps {
  variant?: TypographyVariant;
  color?: Color;
}

type Props =
  | React.HTMLAttributes<HTMLHeadingElement>
  | React.HTMLAttributes<HTMLParagraphElement>
  | React.HTMLAttributes<HTMLSpanElement>;

const Typography = (props: Props & CustomProps) => {
  const { variant, color, ...rest } = props;
  const style = color ? { color: colorMap[color] } : undefined;

  if (!variant) {
    return variantMap.span(rest, style);
  }

  return variantMap?.[variant]?.(rest, style) ?? variantMap.span(rest, style);
};

export default Typography;

import React from "react";
import { Box } from "@material-ui/core";

interface Props {
  children: JSX.Element;
}

const Layout = (props: Props) => <Box py={4}>{props.children}</Box>;

export default Layout;

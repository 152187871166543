import React from "react";
import { Box } from "@material-ui/core";

import Typography from "../Typography/Typography";

const SINCE = 2015;

const About = () => {
  const yearsOfExperience = new Date().getFullYear() - SINCE;

  return (
    <Box id="about">
      <Typography variant="h2">Who am I</Typography>
      <Typography variant="p">
        Just Vicky. No, it's not short for Victoria.
      </Typography>
      <Typography variant="p">
        I'm an engineering manager with over {yearsOfExperience} years of
        software engineering experience within a range of different industries.
      </Typography>
      <Typography variant="p">
        I believe in looking at problems holistically, especially in regards to
        the commercial side.
      </Typography>
      <Typography variant="p">
        I'm an advocate for testing to ensure great system availability and
        reliability to provide end users with the best experience.
      </Typography>
      <Typography variant="p">
        Overseeing the processes outside of coding, I believe understanding
        business value and trade-offs will improve the applications you develop.
      </Typography>
    </Box>
  );
};

export default About;
